import { Injectable, Inject } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import {
  MsalBroadcastService,
  MSAL_GUARD_CONFIG,
  MsalGuardConfiguration,
  MsalService,
} from "@azure/msal-angular";
import { BehaviorSubject, Observable, filter } from "rxjs";
import { EventMessage, EventType } from "@azure/msal-browser";
import { environment } from "src/environments/environment";
@Injectable({ providedIn: "root" })
export class AuthenticationService {
  headers = new HttpHeaders({
    "Content-type": "application/json",
  });

  private _accountInfo$ = new BehaviorSubject<EventMessage | null>(null);
  public accountInfo$: Observable<EventMessage | null> =
    this._accountInfo$.asObservable();

  uniqueId: any;
  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private broadcastService: MsalBroadcastService,
    private msalService: MsalService,
    private http: HttpClient
  ) {
    this.initAccountInfo();
  }

  getToken() {
    const storedAccountInfo =
      JSON.parse(sessionStorage.getItem("accountInfo")) || {};
    return storedAccountInfo?.payload?.accessToken;
  }

  private initAccountInfo() {
    const storedAccountInfo = sessionStorage.getItem("accountInfo");
    if (storedAccountInfo) {
      this._accountInfo$.next(JSON.parse(storedAccountInfo));
    }
  }

  logoutEBR(loggedOutStatus: boolean) {
    let payload = {
      emailId:
        this.msalService?.instance?.getAllAccounts()?.[0]?.localAccountId,
      isLoggedOut: loggedOutStatus,
    };
    const headers = new HttpHeaders().set("content-type", "application/json");
    const url = `${environment.NET_API_HOST}/globalpreferences/user/logout`;
    const body = JSON.stringify(payload);
    this.http.post(url, body, { headers: headers }).subscribe(() => {
      if (loggedOutStatus) {
        this.msalService.logoutRedirect({
          postLogoutRedirectUri: environment.ECOLAB_WEB,
        });
        this.msalService.logout();
      }
    });
  }

  goToLogin(loggedOutStatus: boolean) {
    let payload = {
      emailId:
        this.msalService?.instance?.getAllAccounts()?.[0]?.localAccountId,
      isLoggedOut: loggedOutStatus,
    };
    const headers = new HttpHeaders().set("content-type", "application/json");
    const url = `${environment.NET_API_HOST}/globalpreferences/user/logout`;
    const body = JSON.stringify(payload);
    this.http.post(url, body, { headers: headers }).subscribe(() => {
      if (loggedOutStatus) {
        this.msalService.logout();
      }
    });
  }

  checkLoggedOutService() {
    const url = `${environment.NET_API_HOST}/globalpreferences/user/logout`;
    return this.http.get<any>(url, {
      params: {
        EmailId:
          this.msalService?.instance?.getAllAccounts()?.[0]?.localAccountId,
      },
    });
  }

  isUnauthorized() {
    const url = `${environment.API_HOST}users/applications/EBR`;
    return this.http.get<any>(url);
  }

  updateUserLoginStatus(
    emailId: string,
    loginStatus: boolean
  ): Observable<any> {
    let loginStatusPayload = {
      emailId: emailId,
      isLoggedOut: loginStatus,
    };
    const headers = new HttpHeaders().set("content-type", "application/json");
    const url = `${environment.NET_API_HOST}/globalpreferences/user/logout`;
    const body = JSON.stringify(loginStatusPayload);
    return this.http.post(url, body, { headers: headers });
  }
}
