<mat-toolbar class="toolbar-main">
  <a href="https://www.ecolab.com">
    <img
      alt="Partnership impact heading icon"
      src="{{ 'assets/images/logo.png' }}"
    />
  </a>
  <span class="spacer"></span>

  <div class="user-menu">
    <svg
      width="48"
      height="32"
      viewBox="0 0 48 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.9998 2.66675C16.6398 2.66675 10.6665 8.64008 10.6665 16.0001C10.6665 23.3601 16.6398 29.3334 23.9998 29.3334C31.3598 29.3334 37.3332 23.3601 37.3332 16.0001C37.3332 8.64008 31.3598 2.66675 23.9998 2.66675ZM17.7998 24.6667C19.5465 23.4134 21.6798 22.6667 23.9998 22.6667C26.3198 22.6667 28.4532 23.4134 30.1998 24.6667C28.4532 25.9201 26.3198 26.6667 23.9998 26.6667C21.6798 26.6667 19.5465 25.9201 17.7998 24.6667ZM32.1865 22.8267C29.9332 21.0667 27.0932 20.0001 23.9998 20.0001C20.9065 20.0001 18.0665 21.0667 15.8132 22.8267C14.2665 20.9734 13.3332 18.6001 13.3332 16.0001C13.3332 10.1067 18.1065 5.33341 23.9998 5.33341C29.8932 5.33341 34.6665 10.1067 34.6665 16.0001C34.6665 18.6001 33.7332 20.9734 32.1865 22.8267Z"
        fill="white"
      />
      <path
        d="M23.9998 8.00008C21.4265 8.00008 19.3332 10.0934 19.3332 12.6667C19.3332 15.2401 21.4265 17.3334 23.9998 17.3334C26.5732 17.3334 28.6665 15.2401 28.6665 12.6667C28.6665 10.0934 26.5732 8.00008 23.9998 8.00008ZM23.9998 14.6667C22.8932 14.6667 21.9998 13.7734 21.9998 12.6667C21.9998 11.5601 22.8932 10.6667 23.9998 10.6667C25.1065 10.6667 25.9998 11.5601 25.9998 12.6667C25.9998 13.7734 25.1065 14.6667 23.9998 14.6667Z"
        fill="white"
      />
    </svg>

    <span class="user-name">{{ userName }}</span>
    <button
      [id]="isDropdownOpen ? ' header-view-less-button' :'header-view-more-button' "
      mat-icon-button
      [matMenuTriggerFor]="menu"
      (click)="toggleDropdown()"
      #menuTrigger="matMenuTrigger"
      class="dropdown-button"
      [class.active]="isDropdownOpen"
    >
      <!-- <mat-icon>expand_more</mat-icon> -->
      <mat-icon>{{ isDropdownOpen ? "expand_less" : "expand_more" }}</mat-icon>
    </button>
  </div>

  <!-- <mat-icon>account_circle</mat-icon> -->
  <!-- <h5>{{ userName }}</h5> -->
  <!-- <p>{{ userName }}</p> -->
  <!-- <button mat-icon-button [matMenuTriggerFor]="menu"> -->
  <!-- <mat-icon>account_circle</mat-icon> -->
  <!-- <p>{{ userName }}</p> -->
  <!-- <mat-icon>arrow_drop_down</mat-icon> -->
  <!-- </button> -->

  <mat-menu #menu="matMenu">
    <!-- <button mat-menu-item class="logout-button">
        <mat-icon class="icon">logout</mat-icon>
        Logout
      </button> -->
    <mat-card appearance="outlined" class="logout-button">
      <!-- <mat-card-content>Logout</mat-card-content> -->
      <mat-card-actions>
        <button mat-menu-item class="logout-button" (click)="onLogout()">
          <mat-icon class="icon"> logout </mat-icon>
          {{'EBR.LOGOUT' | translate}}
        </button>
      </mat-card-actions>
    </mat-card>
  </mat-menu>
  <!-- <mat-menu #menu="matMenu">
        <button mat-menu-item>Profile</button>
        <button mat-menu-item>Logout</button>
      </mat-menu> -->
</mat-toolbar>
