<!-- <div class="customer-main-details">
  <div class="customer-main-details-wrapper">
    <div class="customer-main-details-left">
      <div>
        <p class="customer-main-details-tvd">Total Value</p>
        <div class="customer-main-details-tvd-wrapper">
          <div class="customer-main-details-Million">
            <span>
              <span class="customer-main-details-dollor">$</span>
              <span class="customer-main-details-data">{{totalValue}}</span>
              <span>M</span>
            </span>
          </div>
          <div>
            <div class="customer-main-details-usd">USD</div>
          </div>
        </div>
        <div>
          <div class="customer-visibility">
            <div class="ellipse ellipse-tvd"></div>
            <div class="customer-data-label tvd-label-align">Total Value Delivered</div>
          </div>
          <div class="customer-visibility">
            <div class="ellipse ellipse-opportunity"></div>
            <div class="customer-data-label">Total Value Opportunity</div>
          </div>
        </div>
      </div>
    </div>
    <div class="customer-main-details-right">
      <div class="customer-value customer-value-align">
        <span class="customer-value-wrapper">
          <span class="customer-value-wrapper__dollar">$</span>
          <span>{{totalValueDeliveredInMillion}}</span>
          <span>M</span>
          <span class="customer-value-usd">USD</span>
        </span>
        <div id="tvd"></div>
        <div class="customer-value-label">Value Delivered</div>
      </div>
      <div class="customer-value">
        <div>
          <div class="customer-value-wrapper">
            <span class="customer-value-wrapper__dollar">$</span>
            <span>{{totalOpportunityInMillion}}</span>
            <span>M</span>
            <span class="customer-value-usd">USD</span>
          </div>
          <div id="enterpriseSitePipleine"></div>
          <div class="customer-value-label">Enterprise Site Pipeline</div>
        </div>
      </div>
    </div>
  </div>
</div> -->

<section class="total-value grid">
  <section class="total-value__left col-sm-full col-lg-3">
    <p class="total-value__left_total-label">{{ "EBR.TV" | translate }}</p>
    <p class="total-value__left_total-amount">
      <!-- <span class="dollar">$</span> -->
      <span class="currency-symbol">{{currencyCode | currencySymbol}}</span>
      <span class="total-value-amount">{{
        totalValue | currencyFormatting 
      }}</span>
      <!-- <span>M</span> -->
      <span class="dollar-label">{{currencyCode}}</span>
    </p>
    <div class="total-value__left_labels">
      <div class="erb-flex align-items-center">
        <span class="ellipse ellipse-tvd"></span>
        <p class="customer-data-label tvd-label-align">
          {{ "EBR.TVD" | translate }}
        </p>
      </div>
      <div class="erb-flex align-items-center">
        <span class="ellipse ellipse-opportunity"></span>
        <p class="customer-data-label">{{ "EBR.TVO" | translate }}</p>
      </div>
    </div>
  </section>
  <section class="total-value__right col-sm-full col-lg-9">
    <section class="total-value__right_col">
      <p>
        <!-- <span class="dollar">$</span> -->
        <span class="total-value-amount">{{totalValueDeliveredInMillion | currencySymbol}}{{
          totalValueDeliveredInMillion | currencyFormatting
        }}</span>
        <span class="dollar-label">{{currencyCode}}</span>
      </p>
      <div id="tvd"></div>
      <p class="customer-value-label">{{ "EBR.VD" | translate }}</p>
    </section>
    <section class="total-value__right_col">
      <p>
        <!-- <span class="dollar">$</span> -->
        <span class="total-value-amount">{{totalOpportunityInMillion | currencySymbol}}{{
          totalOpportunityInMillion | currencyFormatting
        }}</span>
        <span class="dollar-label">{{currencyCode}}</span>
      </p>
      <div id="enterpriseSitePipleine"></div>
      <p class="customer-value-label">{{ "EBR.ETP" | translate }}</p>
    </section>
  </section>
</section>
