import { ChangeDetectionStrategy, Component, HostBinding, Inject, inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AuthenticationService } from "src/app/core/services/authentication.service";

@Component({
  selector: 'app-dialog-popup',
  templateUrl: './dialog-popup.component.html',
  styleUrl: './dialog-popup.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DialogPopupComponent {
  
  constructor(
    private router: Router,
    private authService: AuthenticationService,
    public dialogRef: MatDialogRef<DialogPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { message: string }
  ) {}

  reloadPage() {
    window.location.reload();
  }

  onClose(): void {
    this.dialogRef.close();
  }

  logout() {
    this.authService.goToLogin(true);
  }
}
