<div
*ngIf="
isLevelOneTile || isCostBenifitTile
  ? customerTilesData
  : (customerTilesData && totalValueDelivered > 0) || (opportunityAmount > 0)
"
  class="tile"
  [ngClass]="isLevelOneTile && !isCostBenifitTile ? 'level-one' : ''"
>
  <div
    class="tile__header erb-flex erb-flex-col"
    [ngClass]="isLevelOneTile && !isCostBenifitTile ? 'level-one' : ''"
  >
    <div class="tile__header-title erb-flex">
      <p [class]="formattedClassName(customerTilesData?.tileName)">
        {{ customerTilesData?.tileName }}
      </p>
    </div>
    <div class="tile__header-value">
      <p>{{customerTilesData?.totalValueDelivered | currencySymbol}}
        {{ customerTilesData?.totalValueDelivered | currencyFormatting }}
        <span
          class="tile__header-value_quantity-delivered"
          *ngIf="isLevelOneTile"
          >{{ customerTilesData?.quantityDelivered | unitFormatting }}
          {{ customerTilesData?.quantityDeliveredUnit }}</span
        >
        <span
          class="tile__header-value_quantity-delivered"
          *ngIf="!isLevelOneTile || isCostBenifitTile"
          >{{ tvd }}</span
        >
      </p>
    </div>
  </div>
  <div class="tile__chart" [ngClass]="isLevelOneTile && !isCostBenifitTile ? 'level-one' : ''">
    <div [attr.id]="customerTilesData?.tileName"></div>
    <div
      class="tile__chart-label tile-data-unit tiles-opportunit"
      *ngIf="
        customerTilesData?.tileName !== 'Cost & Benefit';
        else costAndBenefit
      "
    >
    {{ customerTilesData?.opportunityAmount | currencySymbol }}
      {{ customerTilesData?.opportunityAmount | currencyFormatting }} 
      {{ "EBR.INOPORTUNITY" | translate }}
    </div>
    <ng-template #costAndBenefit>
      <div class="tile__chart-label tile-data-unit tiles-opportunit">
        {{ customerTilesData?.opportunityAmount | currencySymbol }}
        {{ customerTilesData?.opportunityUnit }}
        {{ customerTilesData?.opportunityAmount | currencyFormatting }}
        {{ "EBR.INOPORTUNITY" | translate }}
      </div>
    </ng-template>
  </div>
  <div class="tile__view-more" [ngClass]="isLevelOneTile && !isCostBenifitTile? 'level-one' : ''"
    [ngClass]="this.tileExpanded ? 'view-more-expand' : ''">
    <mat-expansion-panel
      (opened)="this.tileExpanded = true"
      (closed)="this.tileExpanded = false"
    >
      <mat-expansion-panel-header  [id]="tileExpanded ? 'tiles-view-less-button' :'tiles-view-more-button'">
        {{ this.tileExpanded ? ("EBR.VIEWLESS" | translate) : ("EBR.VIEWMORE" | translate) }}
      </mat-expansion-panel-header>

      <div matExpansionPanelContent>
        <div class="erb-flex erb-flex-col align-units">
            <span
              class="tile__view-more_label label"
              *ngFor="
                let quantityInfo of segregatedInformation
              "
            >
            <ng-container *ngIf="quantityInfo.quantityDeliveredUnit">
              {{ quantityInfo.quantityDelivered | unitFormatting }}
              {{ quantityInfo.quantityDeliveredUnit }}
            </ng-container>          
            </span>
          </div>
        </div>  
        <div class="tile_btn">
          <div class="tile_drop-img">
            <img [src]="isWaterEnergyWaste() ? icon_white : icon_blue" />
          </div>
          <span 
          [ngClass]="isWaterEnergyWaste() ? 'tiles-text-white' : 'tiles-text-blue'" 
            class="tile_btn_help" 
            (click)="toggleHelpTextOverlay()">
            {{'EBR.HOW_WAS_THIS_CAL' | translate}}
          </span>
        </div>
    </mat-expansion-panel>
</div>
<div *ngIf="isOverlayVisible" [id]="getTileId()" class="tile_overlayblack">
  <div class="background" [style.background-color]="bgColor"
  [ngClass]="isWaterEnergyWaste()  ? 'tiles-text-white' : 'tiles-text-blue'">
  <div class="background-close" [ngClass]="isWaterEnergyWaste() ? 'tiles-text-white' : 'tiles-text-black'">
    <p class="left">{{'EBR.DEFINITION' | translate }}</p>
    <a class="tile_close right" (click)="onOverlayClose()">
      <img [src]="isWaterEnergyWaste() ? icon_close_white : icon_close_black" alt="close">
    </a>
  </div>
  <div *ngIf="helpText" class="background-innerText" [ngClass]="isWaterEnergyWaste() ? 'tiles-text-white' : 'tiles-text-black'">
    <p class="main-sentence">{{ helpText[0]  | translate  }}</p>
    <ul>
      <li *ngFor="let item of helpText.slice(1)" class="sub-sentence">{{ item | translate }}</li>
    </ul>
  </div>
</div>
</div>
</div>
