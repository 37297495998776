<!-- unauthorized.component.html -->
<div class="unauthorized-container">
  <mat-toolbar class="toolbar-main">
    <a href="https://www.ecolab.com">
      <img alt="Ecolab logo" src="assets/images/logo.png" />
    </a>
  </mat-toolbar>

  <div class="content-wrapper">
    <div class="error-code">401</div>
    <div class="content-overlay">
      <h1>Unauthorized User</h1>
      <p>
        We can't log you in at this time because your account does not have
        authorization to view the Enterprise Business Review application.
      </p>
      <button mat-raised-button (click)="returnToMain()">
        Return to Ecolab.com
      </button>
    </div>
  </div>

  <app-footer></app-footer>
</div>
