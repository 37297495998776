import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "unitFormatting",
})
export class UnitFormattingPipe implements PipeTransform {
  transform(value: string, locale: string = "en"): string {
    if (!value) {
      return "";
    }

    return new Intl.NumberFormat(locale, {
      style: "decimal",
      notation: "compact",
      compactDisplay: "short",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(Math.round(parseFloat(value)));
  }
}
