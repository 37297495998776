import { Component, Input,Output,EventEmitter } from '@angular/core';
import { ITileData } from 'interfaces/ITileData';
import { VIEW_MORE, VIEW_MORE_ICON_BLUE, VIEW_MORE_ICON_WHITE } from 'src/app/core/constants/app.constants';

@Component({
  selector: 'app-cards',
  templateUrl: './cards.component.html',
  styleUrl: './cards.component.scss'
})
export class CardsComponent {

 @Input() bgColor;
 @Input() customerTilesData : ITileData;
 viewLabelText = VIEW_MORE;
 expandTile:boolean=false;
 viewIcon_blue=VIEW_MORE_ICON_BLUE
 viewIcon_white=VIEW_MORE_ICON_WHITE;
 isLevelOneTile: any = false;
 levelOneTiles:any = ['water', 'energy', 'waste'];

 ngAfterViewInit() {
  if (this.customerTilesData) {
    this.isLevelOneTile = this.levelOneTiles.indexOf(this.customerTilesData.tileName.toLowerCase()) !== -1;
  }
 }

//  viewMoreClicked()
//  {
//   if(this.expandTile)
//   {
//     this.expandTile=false;
//     this.viewLabelText=VIEW_MORE;
//     this.viewIcon_blue=VIEW_MORE_ICON_BLUE
//     this.viewIcon_white=VIEW_MORE_ICON_WHITE
//   }
//   else
//   {
//     this.expandTile=true;
//     this.viewLabelText=VIEW_LESS;
//     this.viewIcon_blue=VIEW_LESS_ICON_BLUE;
//     this.viewIcon_white=VIEW_LESS_ICON_WHITE;
//   }
//  }
}
