<h2 class="popup-title" mat-dialog-title>
    <img src="/assets/images/alert_icon.png" alt="Icon" class="title-icon">
    {{ 'EBR.CONN_TITLE' | translate }}
  </h2>
<mat-dialog-content>
    {{ 'EBR.CONN_DESC' | translate }}
</mat-dialog-content>
<mat-dialog-actions>
  <button class="popup-btn" mat-button mat-dialog-close (click)="logout()">{{ 'EBR.CONN_LOGIN' | translate }}</button>
  <button class="popup-btn" mat-button mat-dialog-close cdkFocusInitial (click)="reloadPage()">{{ 'EBR.CONN_RELOAD' | translate }}</button>
</mat-dialog-actions>