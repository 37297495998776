import { Component, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { TranslationService } from "services/translation.service";
@Component({
  selector: "app-footer",
  templateUrl: "./footer.component.html",
  styleUrl: "./footer.component.scss",
})
export class FooterComponent implements OnInit {
  constructor(
    public translateService: TranslationService,
    public translate: TranslateService
  ) {}

  currentYear: number;
  ngOnInit() {
    this.currentYear = new Date().getFullYear();
    this.translateService.selectedLanguage();
  }
}
