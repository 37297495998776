import { Injectable } from '@angular/core';
import { IStepTracker } from 'interfaces/IStepTracker';

@Injectable({
    providedIn: 'root'
})
export class EBRConstants {


    public readonly stepTracker:IStepTracker[]=[
      {stepIndex:1,isCompleted:false,isCurrent:false},
      {stepIndex:2,isCompleted:false,isCurrent:false},
      {stepIndex:3,isCompleted:false,isCurrent:false},
      {stepIndex:4,isCompleted:false,isCurrent:false},
      {stepIndex:5,isCompleted:false,isCurrent:false}];

    readonly index_1:number = 0;
    readonly index_2:number = 1;
    readonly index_3:number = 2;
    readonly index_4:number = 3;
    readonly index_5:number = 4;
    
    readonly step_1:string = 'Step-1';
    readonly step_2:string = 'Step-2';
    readonly step_3:string = 'Step-3';
    readonly step_4:string = 'Step-4';
    readonly step_5:string = 'Step-5';

    readonly heading:string= 'My Executive Business Review'
    readonly step_1_title:string='Initialization'
    readonly step_2_title:string='Initialization'
    readonly step_3_title:string='Customer Selection'
    readonly step_4_title:string='Division'
    readonly step_5_title:string='Customer or Geography'

    readonly initialized_steps:initialization_steps[]=
    [{stepName:this.step_1,stepTitle:this.step_1_title},
    {stepName:this.step_3,stepTitle:this.step_3_title},
    {stepName:this.step_4,stepTitle:this.step_4_title},
    {stepName:this.step_5,stepTitle:this.step_5_title}]

    businessDriverDeleted ="The Key Business Driver has been deleted successfully!";
        
    readonly level_1:number = 1;
    readonly level_2:number = 2;
    readonly level_3:number = 3;
    readonly level_4:number = 4;
    readonly level_5:number = 5;
    readonly date_YYYY_MM_DD:string ='YYYY-MM-DD';

}

type initialization_steps={
  stepName:string;
  stepTitle:string;
}

export const EBR_DATE_FORMAT = {
    parse: {
      dateInput: 'MMM-YYYY',
    },
    display: {
      dateInput: 'MMM-YYYY',
      monthYearLabel: 'MMM YYYY',
      dateA11yLabel: 'LL',
      monthYearA11yLabel: 'MMMM YYYY',
    },
  };