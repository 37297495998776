<div class="card" [style.background-color]="bgColor" [id]="expandTile? 'cardExpansion' : ''">
    <ng-content></ng-content>
    <!-- <div class="card-view-more" [ngClass]="isLevelOneTile ? 'level-one' : '' ">
        <div class="card-view-more-text" (click)="viewMoreClicked()">{{viewLabelText}}</div>
        <div class="view-more-drop-img" (click)="viewMoreClicked()">
            <img *ngIf="customerTilesData?.tileName !== 'Cost & Benefit'; else costBenefits" alt="dropdown" src="{{'assets/images/'+viewIcon_white}}"/>
       
            <ng-template #costBenefits>
                <img  alt="dropdown" src="{{'assets/images/'+viewIcon_blue}}" />
            </ng-template>
        </div>
    </div> -->
</div>