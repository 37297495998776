import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "currencyFormatting",
})
export class CurrencyFormattingPipe implements PipeTransform {
  currencyCode: any = "";
  unitOfMeasure: string = '';
  transform(
    value: string,
    currency: string = sessionStorage?.getItem('currency'),
    locale: string = "en"
  ): string {
    if (!value) {
      return "";
    }
    return new Intl.NumberFormat(locale, {
      // style: "currency",
      currency: sessionStorage?.getItem('currency'),
      notation: "compact",
      compactDisplay: "short",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(Math.round(parseFloat(value)));
  }
}
