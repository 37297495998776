import { Component, Input } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { TranslationService } from "services/translation.service";
@Component({
  selector: "app-total-value",
  templateUrl: "./total-value.component.html",
  styleUrl: "./total-value.component.scss",
})
export class TotalValueComponent {
  @Input() totalValue: string;
  @Input() totalValueDeliveredInMillion: string;
  @Input() totalOpportunityInMillion: string;
  currencyCode: string = '';
  
  constructor(
    public translateService: TranslationService,
    public translate: TranslateService
  ) {}

  ngOnInit() {
    this.currencyCode = sessionStorage.getItem('currency');
    this.translateService.selectedLanguage();
  }
}
