import { Component, Input, ViewChild } from "@angular/core";
import { MatMenuTrigger } from "@angular/material/menu";
import { MsalService } from "@azure/msal-angular";
import { TranslationService } from "services/translation.service";
import { AuthenticationService } from "src/app/core/services/authentication.service";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrl: "./header.component.scss",
})
export class HeaderComponent {
  @Input() title: string = "";
  userName: any;

  isDropdownOpen = false;
  @ViewChild("menuTrigger") menuTrigger: MatMenuTrigger;

  constructor(
    private msalService: MsalService,
    public translateService: TranslationService,
    private authService: AuthenticationService
  ) {}

  ngOnInit() {
    const accountInfo = JSON.parse(
      sessionStorage.getItem("accountInfo") || "{}"
    );

    this.userName = accountInfo.payload?.account?.name;
    // Translation Service
    this.translateService.selectedLanguage();
  }

  toggleDropdown() {
    this.isDropdownOpen = !this.isDropdownOpen;
  }

  ngAfterViewInit() {
    this.menuTrigger.menuClosed.subscribe(() => {
      this.isDropdownOpen = false;
    });
  }

  onLogout() {
    sessionStorage.removeItem("ebrData");
    this.authService.logoutEBR(true);
  }
}
