import { Injectable } from "@angular/core";
import {
  BehaviorSubject,
  Observable,
  catchError,
  map,
  throwError,
} from "rxjs";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "../../../../../environments/environment";
import { MatSnackBar } from "@angular/material/snack-bar";
import { IEBRInput } from "interfaces/IEbrInput";
import { IStepTracker } from "interfaces/IStepTracker";
import { EBRConstants } from "src/app/core/classes/ebr-constants";
import { Metrics } from "../../interfaces/value-active-metrics.interface";
@Injectable({
  providedIn: "root",
})
export class EbrSetupService {
  accountInfo: any;
  customerId: any;

  public kbdDataSubject = new BehaviorSubject<any>(null);
  kbdData$ = this.kbdDataSubject.asObservable();

  public vamDataSubject = new BehaviorSubject<[]>(null);
  vamData$ = this.vamDataSubject.asObservable();

  public retrievedDeletedVamSubject = new BehaviorSubject<[]>(null);
  retrievedDeletedVams$ = this.retrievedDeletedVamSubject.asObservable();

  public vamDataDefSubject = new BehaviorSubject<any>(null);
  vamDataDef$ = this.vamDataDefSubject.asObservable();

  public insightDataSubject = new BehaviorSubject<[]>(null);
  insightData$ = this.insightDataSubject.asObservable();

  public retrievedDeletedInsightSubject = new BehaviorSubject<[]>(null);
  retrievedDeletedinsights$ = this.retrievedDeletedInsightSubject.asObservable();

  public insightDataDefSubject = new BehaviorSubject<any>(null);
  insightDataDef$ = this.insightDataDefSubject.asObservable();

  public kbdDataDefSubject = new BehaviorSubject<any>(null);
  kbdDataDef$ = this.kbdDataDefSubject.asObservable();

  public updatedServiceInsightData = new BehaviorSubject<[]>(null);
  updatedServiceInsightData$ = this.updatedServiceInsightData.asObservable();

  public stepTracker: BehaviorSubject<IStepTracker[]> = new BehaviorSubject([
    ...this._ebrConstant.stepTracker,
  ]);

  public ebrData: BehaviorSubject<IEBRInput> = new BehaviorSubject({
    isEnabled: false,
  });

  public customerSelctionData: BehaviorSubject<object> = new BehaviorSubject<object>({});

  constructor(
    public _ebrConstant: EBRConstants,
    private http: HttpClient,
    private _snackBar: MatSnackBar
  ) { }

  retrieveKBD(): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        "Access-Control-Allow-Origin": "*",
      }),
    };
    this.accountInfo = JSON.parse(sessionStorage.getItem("accountInfo"));
    let ebrData = JSON.parse(sessionStorage.getItem("ebrData"));
    this.customerId = ebrData?.customerSelection?.customerDetail?.customerID;
    return this.http.get(
      `${environment.NET_API_HOST}/ebr/keybusinessdriver?userid=${this.accountInfo?.payload?.uniqueId}&customerid=${this.customerId}`,
      httpOptions
    );
  }

  retrieveVAM(): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        "Access-Control-Allow-Origin": "*",
      }),
    };
    this.accountInfo = JSON.parse(sessionStorage.getItem("accountInfo"));
    let vamData = JSON.parse(sessionStorage.getItem("ebrData"));
    this.customerId = vamData?.customerSelection?.customerDetail?.customerID;
    return this.http.get<Metrics>(
      `${environment.NET_API_HOST}/ebr/valueactivitymetrics?userid=${this.accountInfo?.payload?.uniqueId}&customerid=${this.customerId}`,
      httpOptions
    );
  }
  retrieveInsight(): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        "Access-Control-Allow-Origin": "*",
      }),
    };
    this.accountInfo = JSON.parse(sessionStorage.getItem("accountInfo"));
    let insightData = JSON.parse(sessionStorage.getItem("ebrData"));
    this.customerId = insightData?.customerSelection?.customerDetail?.customerID;
    return this.http.get<Metrics>(
      `${environment.NET_API_HOST}/ebr/serviceinsights?customerid=${this.customerId}`,
      httpOptions
    );
  }
  publishKBD(data: any): Observable<any> {
    return this.http
      .post(`${environment.NET_API_HOST}/ebr/publish`, data, {
        responseType: "text",
      })
      .pipe(
        map((response) => {
          try {
            return JSON.parse(response);
          } catch {
            return response;
          }
        }),
        catchError((error) => {
          console.error("Error publishing KBD data:", error);
          return throwError(error);
        })
      );
  }

  openSnackBar(publish: boolean) {
    const successPublishMsg = "The Data has been Published successfully";
    const errorPublishMsg = "Error publishing data! Please try again";
    const message = publish ? successPublishMsg : errorPublishMsg;
    const action = "";
    const css = publish ? "success-snackbar" : "error-snackbar";

    this._snackBar.open(message, action, {
      duration: 4000,
      horizontalPosition: "center",
      verticalPosition: "top",
      panelClass: [css],
    });
  }

  updateKbdData(data: any) {
    this.kbdDataSubject.next(data);
  }

  updateVamData(data: any) {
    this.vamDataSubject.next(data)
  }

  updateVamDefData(data: any) {
    this.vamDataDefSubject.next(data)
  }

  updateDeletedVams(data: any) {
    this.retrievedDeletedVamSubject.next(data)
  }

  updateInsightData(data: any) {
    this.insightDataSubject.next(data)
  }

  updateInsightDefData(data: any) {
    this.insightDataDefSubject.next(data)
  }

  updateServiceInsightData(data: any) {
    this.updatedServiceInsightData.next(data)
  }

  updateDeletedInsights(data: any) {
    this.retrievedDeletedInsightSubject.next(data)
  }

  updateKbdDataDef(data: any) {
    this.kbdDataDefSubject.next(data);
  }

  public modifyEBRDataState(ebrInput: IEBRInput) {
    this.ebrData.next(ebrInput);
  }

  public changeIsCurrent(index: number) {
    const changeTrack = this.stepTracker.getValue();
    changeTrack[index].isCurrent = true; //making current stepIndex as True
    //making rest all as False
    changeTrack.forEach((item) => {
      if (item.stepIndex != changeTrack[index].stepIndex) {
        item.isCurrent = false;
      }
    });
    this.stepTracker.next(changeTrack);
  }

  //Below method will update the steptracker based on completion and Current Step
  public changeIsCompleted(index: number, isCompleted: boolean) {
    const changeTrack = this.stepTracker.getValue();
    changeTrack[index].isCompleted = isCompleted;
    this.stepTracker.next(changeTrack);
  }
  // Public
  setEBRData(data) {
    this.ebrData.next(data);
    this.customerSelctionData.next(data);
  }
}
