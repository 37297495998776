import { Component, EventEmitter, Input,Output } from "@angular/core";
import * as Highcharts from "highcharts";
import { TranslateService } from "@ngx-translate/core";
import { TranslationService } from "services/translation.service";
import { ITileData } from "interfaces/ITileData";
import {
  COST_AND_BENEFITS,
  TVD,
  VIEW_LESS,
  VIEW_MORE,
  WATER,
  WASTE,
  ENERGY,
  HOW_WAS_THIS_CALCULATED_BLUE,
  HOW_WAS_THIS_CALCULATED_WHITE,
  ICON_CLOSE_BLACK,
  ICON_CLOSE_WHITE
} from "src/app/core/constants/app.constants";
import { CustomerService } from "business-review/services/customer.service";
import { CurrencySymbolPipe } from "../pipes/currency-symbol.pipe";
@Component({
  selector: "app-tiles-data",
  templateUrl: "./tiles-data.component.html",
  styleUrl: "./tiles-data.component.scss",
})
export class TilesDataComponent {
  @Input() customerTilesData: ITileData;
  @Output() toggleOverlay = new EventEmitter<void>();
  @Input() id: string;
  @Input() isLevel2: boolean;
  bgColor = "";
  tileImage: string = "";
  viewMore = VIEW_MORE;
  viewLess = VIEW_LESS;
  tvd = TVD;
  costAndBenefit = COST_AND_BENEFITS;
  isLevelOneTile: any = false;
  levelOneTiles: any = ["water", "energy", "waste", "cost & benefit"];
  tileExpanded: boolean = false;
  isCostBenifitTile = false;
  icon_blue = HOW_WAS_THIS_CALCULATED_BLUE;
  icon_white = HOW_WAS_THIS_CALCULATED_WHITE;
  isOverlayVisible = false;
  jsonData: any;
  helpText: string[];
  tileId: string;
  icon_close_black = ICON_CLOSE_BLACK;
  icon_close_white = ICON_CLOSE_WHITE;

  constructor(
    public translateService: TranslationService,
    public translate: TranslateService,
    private customerService: CustomerService
  ) {}
  ngOnInit() {
    this.translateService.selectedLanguage();
    this.bgColor =
      this.customerTilesData?.tileName === "Water" ||
      this.customerTilesData?.tileName === "Energy" ||
      this.customerTilesData?.tileName === "Waste"
        ? "var(--secondary-dark-blue, #093675)"
        : "#fff";
  }
  ngAfterViewInit() {
    if (this.customerTilesData) {
      this.isCostBenifitTile =
        this.customerTilesData.tileName === this.costAndBenefit;
      this.isLevelOneTile =
        this.levelOneTiles.indexOf(
          this.customerTilesData.tileName.toLowerCase()
        ) !== -1;
      this.getHighChartData(this.customerTilesData);
    }
  }

  get totalValueDelivered(): number {
    return this.customerTilesData
      ? parseInt(this.customerTilesData?.totalValueDelivered, 10)
      : 0;
  }

  get opportunityAmount(): number {
    return this.customerTilesData
      ? parseInt(this.customerTilesData?.opportunityAmount, 10)
      : 0;
  }

  getHighChartData(customerData: ITileData): void {
    this.highCharts(
      Number(customerData?.totalValueDelivered?.replace(/\,/g, "")),
      Number(customerData?.opportunityAmount?.replace(/\,/g, ""))
    );
    Highcharts.setOptions({
      lang: {
        thousandsSep: ",",
      },
    });
  }

  formattedClassName(tileName: string): string {
    if (!tileName) {
      return "icon-default";
    }
    return "icon-" + tileName.toLowerCase().replace(/[^a-z0-9]+/g, "-");
  }

  highCharts(totalTVDAmount, totalOpportunityAmount): void {
    // @ts-ignore
    Highcharts.chart(this.customerTilesData?.tileName, {
      chart: {
        type: "bar",
        margin: [0, 0, 0, 0],
        height: 25,
        width: 300,
        backgroundColor: "transparent",
      },
      plotOptions: {
        bar: {
          borderRadius: "50%",
        },
        series: {
          groupPadding: 0,
          pointPadding: 0.3,
          states: {
            inactive: {
              opacity: 1,
            },
          },
        },
      },
      title: {
        text: "",
      },
      xAxis: {
        visible: false,
      },
      yAxis: {
        visible: false,
      },
      tooltip: {
        enabled: true,
        headerFormat: "<table>",
        pointFormat:
        // Note: new CurrencySymbolPipe().transform('EUR') - pipe is expecting default dummy value 'EUR' - is dummy, UI is rendering as expected.Tested in dev.
          '<tr><p style="padding:0;font-size:1.5em">{series.userOptions.category}:</p><td style="padding:0;font-size:1.5em">' + " </td> <b style='padding:0;font-size:1.5em'>"  + new CurrencySymbolPipe().transform('EUR') + '</b><td style="padding:0; font-size: 1.5em"><b>{point.y}</b></td></tr>' 
      },
      credits: {
        enabled: false,
      },
      series: [
        {
          borderColor: "#3299fd",
          showInLegend: false,
          category: "Total Value Delivered",
          data: [{ y: Number(totalTVDAmount.toFixed(2)), color: "#3299fd" }],
        },
        {
          borderColor: "#4fc2b0",
          showInLegend: false,
          category: "Total Value Pipeline",
          data: [
            { y: Number(totalOpportunityAmount.toFixed(2)), color: "#4fc2b0" },
          ],
        },
      ],
    });
  }

  get segregatedInformation() {
    return this.customerTilesData?.segregatedInformation?.filter((qd) => {
      const quantity = Math.round(Number(qd?.quantityDelivered));
      return !isNaN(quantity) && quantity > 0;
    });
  }

  toggleHelpTextOverlay() {
    this.toggleOverlay.emit();
    this.isOverlayVisible = true;
    this.tileId = this.getTileId().toLowerCase().trim();
    this.fetchHelpText();
  }

  getTileId(): string {
    const data = this.customerTilesData;
    // Check if data is a valid object and contains the tileName property.
    if (data && typeof data === "object" && "tileName" in data) {
      return data.tileName.toLowerCase();
    }
    return "";
  }

  onOverlayClose() {
    this.customerService.toggleOverlayVisibility(false);
    this.isOverlayVisible = false;
  }

  /**
   * Fetches and assigns help text based on the tile ID.
   *
   * - Subscribes to translation stream to get jsonData.
   * - Ensures jsonData is an array.
   * - Searches for help text in Level 1, if not found, searches in Level 2.
   * - Assigns found help text to this.helpText.
   */
  fetchHelpText(): void {
    this.translate.stream("data").subscribe((values) => {
      this.jsonData = values;

      if (Array.isArray(this.jsonData)) {
        // Search in Level 1
        const level1 = this.jsonData.find((level: any) => level.level === "1");
        this.helpText = this.getHelpTextForLevel(level1, this.tileId);

        // If not found, search in Level 2
        if (!this.helpText) {
          const level2 = this.jsonData.find(
            (level: any) => level.level === "2"
          );
          this.helpText = this.getHelpTextForLevel(level2, this.tileId);
        }
      }
    });
  }

  /**
   * Searches for HelpText in the specified level.
   *
   * @param {any} level - The level to search in.
   * @param {string} tileId - Formatted tile ID.
   * @returns {string[] | undefined} - Found HelpText or undefined.
   */
  getHelpTextForLevel(level: any, tileId: string): string[] | undefined {
    return level?.data?.find(
      (cat: any) => cat.category.toLowerCase().trim() === tileId
    )?.HelpText;
  }

  // Method to check if tileName is 'Water', 'Energy', or 'Waste'
  isWaterEnergyWaste(): boolean {
    const tileName = this.customerTilesData?.tileName;
    return [WATER, ENERGY, WASTE].includes(tileName);
  }

}
