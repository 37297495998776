import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { CustomerService } from "business-review/services/customer.service";
import { Observable, BehaviorSubject, of } from "rxjs";
import { switchMap, tap } from "rxjs/operators";
 
@Injectable({
  providedIn: "root",
})
export class TranslationService {
  private languageInitialized = new BehaviorSubject<boolean>(false);
  private userPreference: string = "en"; // Default language
  defaultLang: Observable<string>;
 
  constructor(
    public translate: TranslateService,
    private customerService: CustomerService
  ) {
    this.selectedLanguage();
  }
 
  selectedLanguage() {
    const storedAccountInfo = JSON.parse(sessionStorage.getItem("accountInfo"));
    const emailId = storedAccountInfo?.payload?.account?.localAccountId;
    this.defaultLang = this.translate.use(this.userPreference);
 
    if (emailId) {
      this.customerService
        .getPreferences(emailId)
        .pipe(
          switchMap((resp) => {
            const langKey = resp?.data?.userPreferences?.langKey;
            const preferredLang = langKey || this.userPreference;
            return this.setLanguage(preferredLang);
          }),
          tap(() => this.languageInitialized.next(true))
        )
        .subscribe();
    }
  }
 
  private setLanguage(language: string): Observable<string> {
    return of(this.translate.use(language)).pipe(
      tap(() => {
        this.translate.setDefaultLang(language);
      }),
      switchMap(() => of(language))
    );
  }
 
  getTranslations(keys: string[]): Observable<any> {
    return this.languageInitialized
      .asObservable()
      .pipe(switchMap(() => this.translate.get(keys)));
  }
}
 
 


// import { Injectable } from "@angular/core";
// import { TranslateService } from "@ngx-translate/core";
// import { CustomerService } from "business-review/services/customer.service";
// import { Observable, BehaviorSubject, of } from "rxjs";
// import { shareReplay, switchMap, tap } from "rxjs/operators";

// @Injectable({
//   providedIn: "root",
// })
// export class TranslationService {
//   private languageInitialized = new BehaviorSubject<boolean>(false);
//   private userPreference: string = "en"; // Default language
//   defaultLang: Observable<string>;
//   private languageLoaded$!: Observable<string>;
  
//   constructor(
//     public translate: TranslateService,
//     private customerService: CustomerService
//   ) {
//     this.selectedLanguage();
//   }

//   selectedLanguage(): Observable<string> {
//     this.setLanguage("en");
//     if (!this.languageLoaded$) {
//       const storedAccountInfo = JSON.parse(
//         sessionStorage.getItem("accountInfo")
//       );
//       const emailId = storedAccountInfo?.payload?.account?.localAccountId;

//       this.languageLoaded$ = this.customerService.getPreferences(emailId).pipe(
//         switchMap((resp) => {
//           const langKey = resp?.data?.userPreferences?.langKey;
//           const preferredLang = langKey || this.userPreference;
//           return this.setLanguage(preferredLang);
//         }),
//         tap(() => this.languageInitialized.next(true)),
//         shareReplay(1)
//       );
//     }
//     return this.languageLoaded$;
//   }

//   private setLanguage(language: string): Observable<string> {
//     return of(this.translate.use(language)).pipe(
//       tap(() => {
//         this.translate.setDefaultLang(language);
//       }),
//       switchMap(() => of(language))
//     );
//   }

//   getTranslations(keys: string[]): Observable<any> {
//     return this.languageInitialized
//       .asObservable()
//       .pipe(switchMap(() => this.translate.get(keys)));
//   }
// }
