export const environment = {
  production: false,
  AUTHORITY_DOMAIN: "wintersnowb2c.b2clogin.com",
  CLIENT_ID: "2ac68067-3ae1-4a78-83ed-710832364957",
  SIGNUP_SIGNIN_AUTHORITY:
    "https://wintersnowb2c.b2clogin.com/stg-account.ecolab.com/B2C_1A_Connect_OIDC_SIGNIN",
  NET_API_HOST: "https://stg-onecustomer-api.ecolab.com",
  PUBLIC_API_HOST: "https://api-cloudhubcentral-st.ecolab.com",
  NET_READ_SCOPE: "https://stg-account.ecolab.com/1C_Api_stg/1C.Read",
  NET_WRITE_SCOPE: "https://stg-account.ecolab.com/1C_Api_stg/1C.Write",
  DI_HOME: "https://stg-myhomepage.ecolab.com",
  API_HOST: "https://api-cloudhubcentral-st.ecolab.com/one-customer/v1/",
  API_SCOPE:
    "https://stg-account.ecolab.com/6de9da30-511c-4fc4-9b3f-467d55f9a73b/scope:read.only",
  APM_SERVER_URL:
    "https://7f18513b91d543dfa791112eabe1324a.apm.eastus2.azure.elastic-cloud.com:443",
  APM_SERVICE_NAME: "one-customer-EBR",
  ECOLAB_WEB: "https://dotcom-stage-cds.ecolab.com/",
};
