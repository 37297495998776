import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, Subject, catchError, throwError } from "rxjs";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { CustomerApi, CustomerLevel1Info, ServiceInsightResponse } from "interfaces/customer.interface";
import { HierarchyLevelRequest, EcolabHierarchyLevelRequest } from "interfaces/customer-level.interface";
import { IL1DetailsRequest } from "interfaces/IDetailsL1";
import { API_CONSTANTS } from "src/app/core/constants/end-point-constants";
import { environment } from "src/environments/environment";
import { Catogory } from "../../interfaces/value-active-metrics.interface";
@Injectable({ providedIn: "root" })
export class CustomerService {
  customerListApi: string = `${environment.API_HOST}${API_CONSTANTS.getCustomers}`;
   isOverlayVisible:BehaviorSubject<Boolean> = new BehaviorSubject(false);
   tileName:Subject<string> = new Subject<string>;



  constructor(private http: HttpClient) { }
  getCatogoryList(): Observable<Catogory> {
    return this.http.get<Catogory>(`${environment.NET_API_HOST}/tvd/valueactivitymetrics/categories`).pipe(catchError(this.errorHandler));
  }
  // Get customer details
  getCustomerList(): Observable<CustomerApi> {
    return this.http
      .get<CustomerApi>(this.customerListApi)
      .pipe(catchError(this.errorHandler));
  }

  // Fetch the L1 Tile details
  getL1TileDetails(
    customerID: string,
    payload: IL1DetailsRequest
  ): Observable<CustomerLevel1Info> {
    return this.http
      .post<CustomerLevel1Info>(
        `${this.customerListApi}/${customerID}/level1`,
        payload
      )
      .pipe(catchError(this.errorHandler));
  }

  // Fetch the L2 Tile details
  getL2TileDetails(
    customerID: string,
    payload: IL1DetailsRequest
  ): Observable<CustomerLevel1Info> {
    return this.http
      .post<CustomerLevel1Info>(
        `${this.customerListApi}/${customerID}/level2`,
        payload
      )
      .pipe(catchError(this.errorHandler));
  }

  // Get spend data
  getSpendData(
    customerID: string,
    payload: IL1DetailsRequest
  ): Observable<CustomerLevel1Info> {
    return this.http
      .post<CustomerLevel1Info>(
        `${this.customerListApi}/${customerID}/spend`,
        payload
      )
      .pipe(catchError(this.errorHandler));
  }

  errorHandler(error: HttpErrorResponse) {
    console.error(error);
    return throwError(error.message || "Server Error");
  }

  // Get hierarchy level
  getHierarchyLevel(
    customerId: string,
    hierarchyLevelData: HierarchyLevelRequest
  ): Observable<any> {
    return this.http
      .post<any>(
        `${this.customerListApi}/${customerId}/customer-hierarchy-levels`,
        hierarchyLevelData
      )
      .pipe(
        catchError((error: HttpErrorResponse) => {
          const errorMessage =
            error.error instanceof ErrorEvent
              ? `An error occurred: ${error.error.message}`
              : `Server returned code: ${error.status}, error message is: ${error.message}`;
          console.error(errorMessage);
          return throwError(() => new Error(errorMessage));
        })
      );
  }

  getEcoHierarchyLevel(
    customerId: string,
    hierarchyLevelData: EcolabHierarchyLevelRequest
  ): Observable<any> {
    return this.http
      .post<any>(
        `${this.customerListApi}/${customerId}/ecolab-hierarchy-levels`,
        hierarchyLevelData
      )
      .pipe(
        catchError((error: HttpErrorResponse) => {
          const errorMessage =
            error.error instanceof ErrorEvent
              ? `An error occurred: ${error.error.message}`
              : `Server returned code: ${error.status}, error message is: ${error.message}`;
          console.error(errorMessage);
          return throwError(() => new Error(errorMessage));
        })
      );
  }

  // Get operating type list
  operatingTypeList(customerId: string): Observable<any> {
    return this.http
      .get<any>(
        this.customerListApi + "/" + customerId + "/operating-type"
      )
      .pipe(catchError(this.errorHandler));
  }

  getDivisionFilters(customerId: string): Observable<any> {
    return this.http
      .get<any>(this.customerListApi + "/" + customerId + "/divisions")
      .pipe(catchError(this.errorHandler));
  }

  getPreferences(emailId: string): Observable<any> {
    const url = environment.NET_API_HOST + '/globalpreferences/userpreferences';
    return this.http.get<any>(url, {
      params: {
        EmailId: emailId
      }
    });
  }
  toggleOverlayVisibility(val:boolean) {
    this.isOverlayVisible.next(val)
   }
  getTileDetails(val:string){
    this.tileName.next(val);
  }
  getServiceInsights(
    customerID: string,payload:IL1DetailsRequest): Observable<ServiceInsightResponse> {
    return this.http
      .post<ServiceInsightResponse>(
        `${this.customerListApi}/${customerID}/services`,
        payload
      )
      .pipe(catchError(this.errorHandler));
  }
}
